import {Injectable, NgZone, OnDestroy} from '@angular/core';
import mapboxgl, {
    AnyLayer,
    AnySourceData,
    EaseToOptions,
    EventData,
    FitBoundsOptions,
    FlyToOptions,
    GeoJSONSource,
    GeoJSONSourceRaw,
    Layer,
    LngLat,
    LngLatBounds,
    LngLatLike,
    Map,
    MapLayerMouseEvent,
    Marker,
    PaddingOptions,
    Sources
} from 'mapbox-gl';
import {TileJsonService} from './tile-json.service';
import {runOutsideAngular} from '../../utils/run-outside-angular';
import {BehaviorSubject, combineLatest, fromEvent, lastValueFrom, Observable, Subject, Subscription} from 'rxjs';
import {SearchStateService} from '../search-state.service';
import {getSearchResultsSource, searchPointsToSourceData, searchResultClusterLayer, searchResultsLayer} from './layer/search-results';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {lngLatFromString} from '../../utils/lng-lat-utils';
import {MapConfigService} from './map-config.service';
import {
    distinctUntilChanged,
    filter,
    map,
    multicast,
    pairwise,
    refCount,
    startWith,
    switchMap,
    take,
    withLatestFrom
} from 'rxjs/operators';
import {PointResult} from '../../model/search-response';
import {bufferTimeLazy} from '../../utils/buffer-time-lazy';
import {removeSpiderifyCluster, spiderifyCluster} from './spiderify';
import * as GeoJSON from 'geojson';
import {MassaModel} from './layer/massa-model';
import {Bag3d} from './layer/bag3d';
import {ApiService} from '../api.service';
import {Feature, MultiPolygon, points, Polygon} from '@turf/helpers';
import {woonplaatsFill} from './layer/woonplaats';
import {SearchWoonplaats} from '../../model/search-woonplaats';
import {MapBuildingLayerConfig, MapConfig, MapLayerConfig, MapMaatvoeringLayerConfig} from '../../model/map-config';
import {Woonplaats} from 'api/models/woonplaats';
import {DynamicComponentService} from '../dynamic-component-service';
import bbox from '@turf/bbox';
import {AzureMapsService} from '../azure-maps.service';
import {poiSymbol} from './layer/poi';
import Threebox from '../threebox/Threebox.js';
import {searchModePaddingOptions} from './search-mode-padding';
import {ACESFilmicToneMapping, AmbientLight, DirectionalLight, sRGBEncoding} from 'three';
import {ClaimPandMenuComponent} from '../../components/claim-pand-menu/claim-pand-menu.component';
import pointOnFeature from '@turf/point-on-feature';
import {initLotFeatureService, lotLayers, lotSourceKey} from './layer/lot';
import {
    pandLayer,
    pandLayerBouwjaarExtrusionColor,
    pandLayerEnergyClassExtrusionColor,
    pandLayerExtrusionColor,
    pandSource
} from './layer/pand';
import {BuildingColorMode} from 'api/models/building-color-mode';
import {FeatureService} from './feature-service.types';
import {
    bouwvlakLayers,
    bouwvlakSourceKey,
    dubbelbestemmingLayers,
    dubbelbestemmingSourceKey,
    enkelbestemmingLayers,
    enkelbestemmingSourceKey,
    initBouwvlakSource,
    initDubbelbestemmingSource,
    initEnkelbestemmingSource
} from './layer/bestemmingsplan';
import {initNatura2000FeatureService, natura2000Layers, natura2000SourceKey} from './layer/natura2000';
import {maatvoeringLayers, maatvoeringLayersExtrusion, maatvoeringSource} from './layer/maatvoering';
import {MaatvoeringLayerMode} from 'api/models/maatvoering-layer-mode';
import {MapEventService} from '../map/map-event/map-event.service';
import {MapEventFeatureClick, MapEventType} from '../map/map-event/map-event.types';
import {PointWithZoom} from '../../model/point';

export const clickableLayers = [
    'pand',
    'search-results',
    'search-result-cluster',
    'lot-fill',
    'azure-poi'
];
export const rightClickableLayers = [
    'pand'
];

export const clickPriority = [
    'search-result-cluster',
    'search-results',
    'vwprojects',
    'bag3d',
    'pand',
    'lot-fill',
    null // click on map (no layer)
];

@Injectable({
    providedIn: 'root'
})
export class MapboxService implements OnDestroy {
    private currentStyleName: string | undefined;
    private mapFeatureServices: FeatureService[] = [];
    private mapSourceIds: string[] = [];
    private mapLayerIds: string[] = [];
    private currentMapConfig: MapConfig | undefined;

    private subscriptions: Subscription[] = [];
    private spiderActive = false;
    private userSources: Sources = {};
    private userLayers: AnyLayer[] = [];
    private massaModelLayer = new MassaModel(
        'vwprojects',
        this.apiService);
    private bag3dLayer = new Bag3d(
        'bag3d');

    private locationPin: Marker;
    private mapConfigUpdated$ = new BehaviorSubject<void>(null);
    private disableLayerClick = false;
    private panelWidth = 1024;
    private mapRequestServiceActive = false;

    map: Map;
    tb: Threebox;
    actionMenu: mapboxgl.Popup;
    claimPandMenu: mapboxgl.Popup;
    mapLayerClick$: Observable<MapLayerMouseEvent & EventData>;
    mapLayerClickPrioritized$: Observable<MapLayerMouseEvent & EventData>;

    readonly mapLoaded$ = new BehaviorSubject(false);
    readonly mapStyleLoaded$ = new BehaviorSubject(false);
    readonly is3D$ = new BehaviorSubject(false);
    readonly bearing$ = new BehaviorSubject(0);
    readonly drawFeatures$ = new BehaviorSubject([]);
    readonly collapseMenu$ = new BehaviorSubject(false);

    constructor(
        private tileJsonService: TileJsonService,
        private ngZone: NgZone,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private mapConfigService: MapConfigService,
        private apiService: ApiService,
        private searchStateService: SearchStateService,
        private dynamicComponentService: DynamicComponentService,
        private azureMapsService: AzureMapsService,
        private mapEventService: MapEventService
    ) { }

    ngOnDestroy(): void {
        this.removeWoonplaatsHighlight();
        this.subscriptions.forEach(it => it.unsubscribe());
    }

    @runOutsideAngular()
    init() {
        if (this.map) {
            throw new Error('Cannot call init twice');
        }

        this.map = new Map({
            container: 'mapbox-container',
            center: [5.13, 52.24],
            zoom: 7,
            antialias: true,
        });

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;
        (window as any).tb = this.tb = new Threebox(
            this.map,
            this.map.getCanvas().getContext('webgl'),
            {
                defaultLights: false,
                enableSelectingObjects: true,
                disableEvents: false,
                multiLayer: true

            }
        );
        this.setupLighting();



        this.massaModelLayer.bagFilter$.subscribe(event => {
            if (!context.disableLayerClick) {
                if (context.bag3dLayer) {
                    context.bag3dLayer.filterBagPand(event);
                }
                else {
                    console.error('Bag3d layer not initialized');
                }

            }
        });

        this.mapLayerClick$ = new Observable<MapLayerMouseEvent & EventData>(function(observer) {
            context.bag3dLayer.onSelected$.subscribe(event => {
                if (!context.disableLayerClick) {
                    observer.next({ ...event, layer: 'bag3d' } as any);
                }
            });

            context.massaModelLayer.onSelected$.subscribe(event => {
                if (!context.disableLayerClick) {
                    context.ngZone.run(() => {
                        const projectId = event?.features[0]?.properties?.id;
                        if (projectId) {
                            observer.next({ ...event, layer: 'vwprojects' } as any);
                        } else {
                            observer.next({
                                ...event,
                                layer: null,
                                features: []
                            } as any);
                        }
                    });
                }
            });


            clickableLayers.forEach(layer => {
                context.map.on('click', layer, event => {
                    if (!context.disableLayerClick) {
                        observer.next({ ...event, layer } as any);
                    }
                });
            });
        });

        rightClickableLayers.forEach(layer => {
            context.map.on('contextmenu', layer, event => {
                const pand = event.features[0].geometry as Polygon;
                const point: Feature<GeoJSON.Point> = pointOnFeature(pand);
                context.toggleClaimBagPand(context, point, event.lngLat); // open rightclick menu to claim a bag pan
            });
        });

        this.bag3dLayer.onContextMenu$.subscribe(async event => {
            const bagId = event.features[0].id.toString();
            const pand = await lastValueFrom(this.apiService.getPandWithPolygon(bagId));
            const point: Feature<GeoJSON.Point> = pointOnFeature(pand.geog);
            context.toggleClaimBagPand(context, point, event.lngLat); // open rightclick menu to claim a bag pand
        });

        this.mapLayerClickPrioritized$ = this.mapLayerClick$.pipe(
            bufferTimeLazy(100),
            map(input => {
                input.sort((a, b) => {
                    const priorityA = clickPriority.indexOf(a.layer);
                    const priorityB = clickPriority.indexOf(b.layer);
                    return priorityA - priorityB;
                });
                return input[0];
            }),
            multicast(new Subject()),
            refCount()
        );

        this.subscriptions.push(fromEvent<Event>(window, 'load').subscribe(() => {
            this.map.resize();
        }));

        this.subscriptions.push(fromEvent<Event>(window, 'resize').subscribe(() => {
            this.onWindowResize();
        }));

        this.subscriptions.push(
            combineLatest([
                this.mapConfigService.asObservable(),
                this.mapConfigUpdated$.asObservable(),
                this.mapLoaded$.asObservable(),
            ]).pipe(
                map(([config, _]) => config),
                withLatestFrom(this.searchStateService.pointResults$.pipe(startWith([]))),
            ).subscribe(([mapConfig, pointResults]) => this.onMapConfigChange(mapConfig, pointResults)),
        );

        this.registerMapEventListeners();
        this.registerSearchStateSelectedListener();
    }

    public toggleClaimBagPand(context, point: Feature<GeoJSON.Point>, lngLat: LngLat) {
        context.ngZone.run(() => {
            if (context.claimPandMenu) {
                context.claimPandMenu.remove();
                context.claimPandMenu = null;
                return;
            }

            context.claimPandMenu = context.createPopUp(lngLat);
            context.claimPandMenu.setDOMContent(
                context.dynamicComponentService.injectComponent(ClaimPandMenuComponent, claimPandMenu => {
                    claimPandMenu.popupModel = context.actionMenu;
                    claimPandMenu.lngLat = new LngLat(point.geometry.coordinates[0], point.geometry.coordinates[1]);
                    claimPandMenu.mapboxService = context;
                })
            );
        });
    }

    onWindowResize() {
        const canvas = this.tb.renderer.domElement;
        const width = canvas.clientWidth;
        const height = canvas.clientHeight;

        this.tb.renderer.setViewport(0, 0, width, height);
        this.tb.camera.aspect = width / height;
        this.tb.camera.updateProjectionMatrix();
        this.tb.repaint();
    }

    enableMassaModelLayer() {
        if (!this.map.getLayer(this.massaModelLayer.id)) {
            this.map.addLayer(this.massaModelLayer, 'pand');
        }
        this.removeMapPadding();
        this.map.setLayoutProperty(this.massaModelLayer.id, 'visibility', 'visible');
        this.massaModelLayer.toggleOn();
    }

    disableMassaModelLayer() {
        if (this.map.getLayer(this.massaModelLayer.id)) {
            this.map.setLayoutProperty(this.massaModelLayer.id, 'visibility', 'none');
            this.massaModelLayer.toggleOff();
        }
    }

    enableBag3dLayer() {
        if (!this.map.getLayer(this.bag3dLayer.id)) {
            this.map.addLayer(this.bag3dLayer, 'pand');
        }
        this.removeMapPadding();
        this.map.setLayoutProperty(this.bag3dLayer.id, 'visibility', 'visible');
        this.bag3dLayer.toggleOn();
    }

    disableBag3dLayer() {
        if (this.map.getLayer(this.bag3dLayer.id)) {
            this.map.setLayoutProperty(this.bag3dLayer.id, 'visibility', 'none');
            this.bag3dLayer.toggleOff();
        }
    }

    enableLayer(layerKey: string) {
        const currentMapConfig = {...this.currentMapConfig};
        const layerConfig = currentMapConfig.layers[layerKey] as MapLayerConfig;

        if (layerConfig) {
            layerConfig.active = true;

            this.currentMapConfig = currentMapConfig;
            this.updateLayers(currentMapConfig);
        }
    }

    disableLayer(layerKey: string) {
        const currentMapConfig = {...this.currentMapConfig};
        if(Object.keys(currentMapConfig).length === 0) {
            console.warn('CurrentMapConfig is empty');
            return;
        }
        if(!currentMapConfig?.layers[layerKey]) {
            console.warn(`Layer with key ${layerKey} not found in currentMapConfig`);
            return;
        }

        const layerConfig = currentMapConfig?.layers[layerKey] as MapLayerConfig;
        if (layerConfig) {
            layerConfig.active = false;

            this.currentMapConfig = currentMapConfig;
            this.updateLayers(currentMapConfig);
        }
    }

    setMapRequestServiceActive(active: boolean) {
        this.mapRequestServiceActive = active;
    }

    setupLighting() {
        this.tb.renderer.outputEncoding = sRGBEncoding;
        this.tb.renderer.toneMapping = ACESFilmicToneMapping;
        this.tb.renderer.toneMappingExposure = 1.2;


        this.tb.lights.dirLight = new DirectionalLight('white', 1);
        this.tb.lights.dirLight.up.set(0, 0, 1);
        this.tb.lights.dirLight.position.set(0, 0, 1); //default; light shining from top
        this.tb.scene.add(this.tb.lights.dirLight);



        this.tb.lights.ambiLight = new AmbientLight('white', .2);
        this.tb.scene.add(this.tb.lights.ambiLight);

        this.adjustLighting();
    }

    adjustLighting() {
        // TODO add comments
        const altitude = 1;
        const azimuth = this.map.getBearing() * (Math.PI / 180) + Math.PI * 1.2;


        const radius = 1024000 / 2;
        const alt = Math.sin(altitude);
        const altRadius = Math.cos(altitude);
        const azCos = Math.cos(azimuth) * altRadius;
        const azSin = Math.sin(azimuth) * altRadius;

        this.tb.lights.dirLight.position.set(azSin, azCos, alt).normalize();
        this.tb.lights.dirLight.position.multiplyScalar(radius);

        this.tb.lights.dirLight.updateMatrixWorld();
        this.tb.lights.dirLight.shadow.camera.updateProjectionMatrix();
    }

    removeMapPadding() {
        this.setMapPadding({
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        });
    }

    highlightWoonplaats(woonplaats: SearchWoonplaats) {
        this.removeWoonplaatsHighlight();
        this.setHighlightedAreaWithSearchWoonplaats(woonplaats);
    }

    setHighlightedAreaWithSearchWoonplaats(result: SearchWoonplaats) {
        lastValueFrom(this.apiService.getWoonplaats(result.identificatie)).then((woonplaats: Woonplaats) => {
            const geoJson = {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: woonplaats.geog as MultiPolygon
                } as Feature<MultiPolygon>
            } as GeoJSONSourceRaw;
            this.addUserSource('woonplaats', geoJson);
            this.addUserLayer(woonplaatsFill);

            this.flyTo({
                center: [result.geog.coordinates[0], result.geog.coordinates[1]],
                padding: 0,
                zoom: 12,
                bearing: 0,
                pitch: 0
            } as FlyToOptions);

            const coordinates = woonplaats.geog.coordinates[0][0];
            const bounds = coordinates.reduce((b: LngLatBounds, coord: any) => {
                return b.extend(coord as LngLatLike);
            }, new LngLatBounds(coordinates[0] as LngLatLike, coordinates[0] as LngLatLike));

            this.map.fitBounds(bounds, {
                padding: 0
            });
        });
    }

    removeWoonplaatsHighlight() {
        if (this.map.getLayer('wpl')) {
            this.removeUserLayer('wpl');
        }
        if (this.map.getSource('woonplaats')) {
            this.removeUserSource('woonplaats');
        }
    }

    setBearing(bearing: number) {
        this.map.setBearing(bearing);
    }

    @runOutsideAngular()
    zoomIn() {
        this.map.zoomIn();
    }

    @runOutsideAngular()
    zoomOut() {
        this.map.zoomOut();
    }

    @runOutsideAngular()
    toggle3D() {
        this.map.setPitch(this.is3D() ? 0 : 45);
    }

    @runOutsideAngular()
    flyTo(options: FlyToOptions) {
        this.map.flyTo(options);
    }

    setLayerClickDisabled(disabled: boolean) {
        this.disableLayerClick = disabled;
    }

    setFilter(layerId: string, layerFilter: any) {
        this.map.setFilter(layerId, layerFilter);
    }

    addUserSource<T>(id: string, source: AnySourceData): T {
        this.userSources[id] = source;
        this.map.addSource(id, source);
        return this.map.getSource(id) as any;
    }

    getUserSource(id: string): any {
        return this.map.getSource(id) as any;
    }

    removeUserSource(id: string) {
        delete this.userSources[id];
        this.map.removeSource(id);
    }

    addUserLayer(layer: AnyLayer) {
        this.userLayers.push(layer);
        this.map.addLayer(layer);
    }

    removeUserLayer(id: string) {
        const index = this.userLayers.findIndex(it => it.id === id);
        if (index !== -1) {
            this.userLayers.splice(index, 1);
        }
        this.map.removeLayer(id);
    }

    getCenter() {
        return this.map.getCenter();
    }

    resetSpider() {
        // timeout to make reset happen after layer click
        setTimeout(() => {
            if (this.spiderActive) {
                removeSpiderifyCluster(this.map);
                this.spiderActive = false;
            }
        });
    }

    setSelected(layer: string, id: string, selected: boolean) {
        if (layer === 'pand') { // pand uses Custom Layer Bag3d
            this.bag3dLayer.setSelected(id, selected);
            return;
        }
        const sourceLayer = (this.map.getLayer(layer) as any)?.sourceLayer;

        this.map.setFeatureState({
            source: layer,
            sourceLayer,
            id
        }, { selected });
    }

    setMapPadding(padding: PaddingOptions) {
        if (this.threeboxLayersEnabled()) {
            padding.left = 0;
        }
        this.map.easeTo({ padding, duration: 400, } as EaseToOptions); // padding property missing
    }

    threeboxLayersEnabled() {
        return this.bag3dLayer.isOn || this.massaModelLayer.isOn; // massamodel also to come
    }

    moveToLocation(geog: number[][], options?: FitBoundsOptions) {
        const boundingBox = bbox(points(geog));
        if (isFinite(boundingBox[0])) {
            this.map.fitBounds([
                [boundingBox[0], boundingBox[1]],
                [boundingBox[2], boundingBox[3]]
            ], options);
        }
    }

    async removeLocationPin(): Promise<void> {
        if (this.locationPin) {
            this.locationPin.remove();
            this.locationPin = undefined;
        }
    }

    addLocationPin(lnglat: LngLatLike) {
        const el = document.createElement('div');
        el.style.backgroundImage = 'url(/assets/sprites/marker-icon.png)';
        el.style.width = '24px';
        el.style.height = '24px';
        this.locationPin = new mapboxgl.Marker(el)
            .setLngLat(lnglat)
            .addTo(this.map);
    }

    updatePOISource() {
        if (!this.getUserSource('azure-poi')) {
            this.addUserSource('azure-poi', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: []
                }
            });
            this.addUserLayer(poiSymbol);
        }
        const mapCenter = this.getCenter();
        this.azureMapsService.getSearchPOICategory(
            mapCenter.lat,
            mapCenter.lng,
            0,
            this.map.getZoom()
        ).pipe(take(1)).subscribe(poi => {
            this.getUserSource('azure-poi').setData({
                type: 'FeatureCollection',
                features: poi.map(item => ({
                    id: item.id,
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [item.position.lon, item.position.lat]
                    },
                    properties: {
                        icon: `poi-${item.icon}`
                    }
                }))
            });
        });
    }

    checkZoomRange() {
        let zoomLevelInRange = true;
        for (const layerKey of Object.keys(this.mapConfigService.getActiveLayers())) {
            switch (layerKey) {
                case 'bestemmingsplan':
                    zoomLevelInRange = this.layerInZoomRange(this.map.getLayer('Enkelbestemming'));
                    break;
                case 'lot':
                    // zoomLevelInRange = this.layerInZoomRange(this.map.getLayer('lot-border'));
                    break;
                case 'natura2000':
                    zoomLevelInRange = this.layerInZoomRange(this.map.getLayer('natura2000'));
                    break;
                case 'maatvoering':
                    zoomLevelInRange = this.inZoomRange(12);
                    break;
                case 'vwprojects':
                    zoomLevelInRange = this.inZoomRange(this.massaModelLayer.minZoom, this.massaModelLayer.maxZoom);
                    break;
            }
            if (!zoomLevelInRange) {
                break;
            }
        }
        return zoomLevelInRange;
    }

    private inZoomRange(minZoom?: number, maxZoom?: number): boolean {
        const currentZoomLevel = this.map.getZoom();
        let isInRange = true;
        if (minZoom) {
            isInRange = currentZoomLevel >= minZoom;
        }
        if (maxZoom) {
            isInRange = isInRange && currentZoomLevel <= maxZoom;
        }
        return isInRange;
    }

    private layerInZoomRange(layer: Layer) {
        return this.inZoomRange(layer.minzoom, layer.maxzoom);
    }

    private registerMapEventListeners() {
        this.map.on('style.load', () => {
            this.onStyleDoneLoading();
            this.mapStyleLoaded$.next(true);
        });
        this.map.on('pitchend', () => this.is3D$.next(this.is3D()));
        this.map.on('rotate', () => {
            this.bearing$.next(-this.map.getBearing());
            if (this.map.getZoom() < 15) {
                return;
            }
            this.adjustLighting();

        });
        this.map.on('click', () => this.resetSpider());
        this.map.on('zoomstart', () => this.resetSpider());
        const onMoveEnd = () => {
            // Skip move events until map is loaded
            if (!this.mapLoaded$.value) {
                return;
            }
            const { lng, lat } = this.map.getCenter();
            const params = {
                location: `${lng.toFixed(5)},${lat.toFixed(5)}`,
                zoom: this.map.getZoom().toFixed(2),
                pitch: this.map.getPitch().toFixed(4),
                bearing: this.map.getBearing().toFixed(4),
            };

            this.updatePOISource();

            this.updateRouteQueryParams(params);
        };
        this.map.on('moveend', onMoveEnd);
        this.map.on('load', () => {
            this.mapLoaded$.next(true);
            onMoveEnd();
        });

        const onSearchResultClick = (event: MapLayerMouseEvent & EventData) => {
            this.ngZone.run(() => {
                const properties = event?.features?.length ? event?.features[0].properties : undefined;
                const type = properties.searchType || event?.features[0].source;
                this.collapseMenu$.next(false);
                if (type === 'lot') {
                    this.router.navigate(['/lots', event?.features[0].id], {
                        queryParamsHandling: 'merge',
                        queryParams: {
                            id: +event?.features[0].id,
                            type: 'lot'
                        }
                    });
                } else if (type === 'pand') {
                    const bagId = event?.features[0].properties.identificatie;
                    this.router.navigate(['/pand', bagId], {
                        queryParamsHandling: 'merge',
                        queryParams: {
                            id: event?.features[0].id,
                            type: 'pand'
                        }
                    });
                } else if (type === 'azure-poi') {
                    const id = event?.features[0].id.toString();
                    this.router.navigate(['/poi', id], {
                        queryParamsHandling: 'merge',
                    });
                } else if (type === 'bag3d') {
                    const bagId = event?.features[0].id.toString().padStart(16, '0');
                    this.router.navigate(['/pand', bagId], {
                        queryParamsHandling: 'merge',
                        queryParams: {
                            id: bagId,
                            type: 'pand'
                        }
                    });
                } else if (type === 'project') {
                    this.router.navigate(['/projects', properties.id], {
                        queryParamsHandling: 'preserve'
                    });
                } else if (type === 'company') {
                    this.router.navigate(['/companies', properties.id], {
                        queryParamsHandling: 'preserve'
                    });
                } else if (type === 'relation') {
                    this.router.navigate(['/relations', properties.id], {
                        queryParamsHandling: 'preserve'
                    });
                } else {
                    console.error('Unknown search result type', type);
                }

            });
        };

        this.onMapLayerPrioritizedClick$(null).subscribe(_ => {
            this.ngZone.run(() => {
                this.searchStateService.clearSearchState();
            });
        });

        this.subscriptions.push(
            this.onMapLayerPrioritizedClick$('pand').pipe(
                filter(event => !!event?.features?.length)
            ).subscribe(event => {
                this.ngZone.run(() => {
                    const selectedPand = event.features[0];
                    const identificatie = selectedPand.properties.identificatie;
                    this.searchStateService.setResultMode(
                        identificatie,
                        'pand',
                        identificatie,
                        {
                            lat: event.lngLat.lat,
                            lon: event.lngLat.lng
                        }
                    );
                });
                onSearchResultClick(event);
            })
        );

        this.subscriptions.push(
            this.onMapLayerPrioritizedClick$('vwprojects').pipe(
                filter(event => !!event?.features?.length)
            ).subscribe(event => onSearchResultClick(event))
        );

        this.subscriptions.push(
            this.onMapLayerPrioritizedClick$('bag3d').pipe(
                filter(event => !!event?.features?.length)
            ).subscribe(event => {
                this.ngZone.run(() => {
                    const selectedPand = event.features[0];
                    const identificatie = selectedPand.id.toString().padStart(16, '0');

                    this.searchStateService.setResultMode(
                        identificatie,
                        'pand',
                        identificatie,
                        {
                            lat: event.lngLat.lat,
                            lon: event.lngLat.lng
                        }
                    );
                });
                onSearchResultClick(event);
            })
        );

        this.subscriptions.push(
            this.onMapLayerPrioritizedClick$('lot-fill').pipe(
                filter(event => !!event?.features?.length)
            ).subscribe(event => {
                if (!this.mapRequestServiceActive) {
                    this.ngZone.run(() => {
                        const selectedFeature = event.features[0];
                        this.searchStateService.setResultMode(
                            [
                                selectedFeature.properties.muncipality_code,
                                selectedFeature.properties.section,
                                selectedFeature.properties.lot_nr
                            ].join(' - '),
                            'lot',
                            selectedFeature.id.toString(),
                            {
                                lat: event.lngLat.lat,
                                lon: event.lngLat.lng
                            });
                    });
                    onSearchResultClick(event);
                } else {
                    const {id} = event.features[0];
                    const mapEvent: MapEventFeatureClick = {
                        type: MapEventType.FeatureClick,
                        payload: {
                            featureId: id
                        }
                    };

                    this.mapEventService.triggerEvent(mapEvent);
                }
            })
        );

        this.subscriptions.push(
            this.onMapLayerPrioritizedClick$('search-results').pipe(
                filter(event => !!event?.features?.length)
            ).subscribe(onSearchResultClick)
        );

        this.subscriptions.push(
            this.onMapLayerPrioritizedClick$('search-result-cluster').pipe(
                switchMap((event) => {
                    const features = event?.features?.length ? event?.features : [];
                    const cluster = event?.features[0] as GeoJSON.Feature<GeoJSON.Point>;
                    const clusterId = event?.features?.length ? event?.features[0]?.properties?.cluster_id : undefined;

                    return new Promise((resolve, reject) => {
                        const source = this.map.getSource('search-results') as GeoJSONSource;
                        const SPIDER_MIN_ZOOM = 14;
                        // Spider if zoom larger than or equal to min zoom, otherwise zoom
                        if (this.map.getZoom() >= SPIDER_MIN_ZOOM) {
                            this.spiderActive = true;
                            spiderifyCluster(this.map, source, cluster, onSearchResultClick);
                        } else {
                            return source.getClusterExpansionZoom(clusterId, (error, zoom) => {
                                if (error) {
                                    reject(error);
                                    return;
                                }

                                resolve([features, Math.min(SPIDER_MIN_ZOOM, zoom)] as PointWithZoom);
                            });
                        }
                    });
                })
            ).subscribe(([features, zoom]: PointWithZoom) => {
                if (features) {
                    this.map.easeTo({
                        center: features[0]?.geometry?.coordinates,
                        zoom
                    } as any);
                }
            })
        );

        this.subscriptions.push(
            this.onMapLayerPrioritizedClick$('azure-poi').pipe(
                filter(event => !!event?.features?.length)
            ).subscribe(onSearchResultClick)
        );
    }

    private onMapLayerPrioritizedClick$(layer: string) {
        return this.mapLayerClickPrioritized$.pipe(
            filter(event => event?.layer === layer)
        );
    }

    private registerSearchStateSelectedListener() {
        this.subscriptions.push(
            combineLatest([
                this.searchStateService.searchState$,
                this.mapLoaded$.pipe(filter(it => it))
            ]).pipe(
                map(([{ type, id }]) => ({ type, id })),
                distinctUntilChanged((a, b) => a.type === b.type && a.id === b.id),
                startWith<{ type: string; id: string } | null>(null),
                pairwise()
            ).subscribe(([previous, current]) => {
                if (previous && (previous.type === 'pand' || previous.type === 'lot')) {
                    this.setSelected(previous.type, previous.id, false);
                }
                if (current.type === 'pand' || current.type === 'lot') {
                    this.setSelected(current.type, current.id, true);
                }
            })
        );
    }

    @runOutsideAngular()
    private onStyleDoneLoading() {
        this.updateCameraPositionToQueryParameters();
        this.setupSearchStateHandlers();
        this.addSprites();
        this.addDrawSprites();
    }

    private setupSearchStateHandlers() {
        this.subscriptions.push(
            this.searchStateService.assetCoordinates$.subscribe(point => {
                if (point) {
                    const padding = this.threeboxLayersEnabled() ? 0 : searchModePaddingOptions('result', this.panelWidth);
                    this.map.flyTo({
                        center: [point.lon, point.lat],
                        padding,
                        zoom: 17
                    } as FlyToOptions);
                }
            }),
            this.searchStateService.pointResults$.subscribe(results => {
                (this.map.getSource('search-results') as GeoJSONSource)?.setData(searchPointsToSourceData(results));
                this.resetSpider();
            })
        );
    }

    private onMapConfigChange(mapConfig: MapConfig, lastSearchResults: PointResult[]) {
        const updateMapStyle = mapConfig.styleName !== this.currentStyleName;

        if (updateMapStyle) {
            this.updateMapStyle(mapConfig.styleName, mapConfig);
        } else {
            this.updateSearchResultsLayer(lastSearchResults);
            this.updateLayers(mapConfig);
        }

        this.currentMapConfig = mapConfig;
    }



    private updateMapStyle(mapStyleName: string, mapConfig: MapConfig) {
        this.removeExtraSourcesAndLayers();
        this.map.setStyle(mapConfig.style);
        this.currentStyleName = mapStyleName;
    }

    private getLabelLayer() {
        return this.map.getStyle().layers.find(layer => layer.type === 'symbol' && layer.layout && layer.layout['text-field'] );
    }

    private getLayerIdToInsertBefore(layerIdBefore: string, fallbackLayerIdBefore = 'threebox_layer') {
        const layerBefore = this.map.getLayer(layerIdBefore);
        const fallbackLayerBefore = this.map.getLayer(fallbackLayerIdBefore);

        return layerBefore ? layerIdBefore : fallbackLayerBefore ? fallbackLayerIdBefore : this.getLabelLayer().id;
    }

    private updateLayers(mapConfig: MapConfig) {
        for (const layerKey of mapConfig.layerOrder) {
            switch (layerKey) {
                case 'buildingLayer':
                    const buildingLayerConfig = mapConfig.layers[layerKey] as MapBuildingLayerConfig;
                    this.updateBuildingLayer(buildingLayerConfig);
                    break;
                case 'vwprojects':
                    const vwprojectsLayerConfig = mapConfig.layers[layerKey] as MapLayerConfig;
                    this.updateVwprojectsLayer(vwprojectsLayerConfig);
                    break;
                case 'lot':
                    const lotLayerConfig = mapConfig.layers[layerKey] as MapLayerConfig;
                    this.updateLotLayer(lotLayerConfig);
                    break;
                case 'bestemmingsplan':
                    const bestemmingsplanLayerConfig = mapConfig.layers[layerKey] as MapLayerConfig;
                    this.updateBestemmingsplanLayer(bestemmingsplanLayerConfig);
                    break;
                case 'natura2000':
                    const natura2000LayerConfig = mapConfig.layers[layerKey] as MapLayerConfig;
                    this.updateNatura2000Layer(natura2000LayerConfig);
                    break;
                case 'maatvoering':
                    const maatvoeringLayerConfig = mapConfig.layers[layerKey] as MapMaatvoeringLayerConfig;
                    const currentMaatvoeringLayerConfig = this.currentMapConfig?.layers[layerKey] as MapMaatvoeringLayerConfig | undefined;
                    this.updateMaatvoeringLayer(maatvoeringLayerConfig, currentMaatvoeringLayerConfig);
                    break;
            }
        }

        this.updateLayerFilters(mapConfig);
    }

    private addLayersOnMap(layers: AnyLayer[], insertBeforeLayerId: string) {
        for (const layer of layers) {
            if (!this.map.getLayer(layer.id)) {


                this.map.addLayer(layer, this.getLayerIdToInsertBefore(insertBeforeLayerId));
                this.mapLayerIds.push(layer.id);
            }
        }
    }

    private removeLayersFromMap(layers: AnyLayer[]) {
        for (const layer of layers) {
            if (this.map.getLayer(layer.id)) {
                this.map.removeLayer(layer.id);
                this.mapLayerIds = this.mapLayerIds.filter(it => it !== layer.id);
            }
        }
    }

    private updateLayerFilters(mapConfig: MapConfig) {
        for (const filterConfig of mapConfig.filters) {
            if (this.map.getLayer(filterConfig.layerId)) {
                this.map.setFilter(filterConfig.layerId, filterConfig.filter);
            }
        }
    }

    private updateSearchResultsLayer(pointResults: PointResult[]) {
        const searchResultsId = 'search-results';
        const searchResultsClusterId = 'search-result-cluster';
        const source = this.map.getSource(searchResultsId);

        if (!source) {
            this.map.addSource(searchResultsId, getSearchResultsSource(pointResults));
        } else {
            if (source.type === 'geojson') {
                source.setData(searchPointsToSourceData(pointResults));
            }
        }

        if (!this.map.getLayer(searchResultsId)) {
            this.map.addLayer(searchResultsLayer);
        }

        if (!this.map.getLayer(searchResultsClusterId)) {
            this.map.addLayer(searchResultClusterLayer);
        }
    }

    private updateBuildingLayer({active, opacity, mode}: MapBuildingLayerConfig) {
        const pandLayerId = 'pand';
        const bag3dLayerId = this.bag3dLayer.id;

        const hasLayers = this.mapLayerIds.indexOf(pandLayerId) !== -1 && this.mapLayerIds.indexOf(bag3dLayerId) !== -1;

        if (active) {
            if (!hasLayers) {
                this.map.addSource(pandLayerId, pandSource);
                this.map.addLayer(this.bag3dLayer, this.getLayerIdToInsertBefore(this.getLabelLayer().id));
                this.map.addLayer(pandLayer, this.getLayerIdToInsertBefore(this.getLabelLayer().id));
                this.map.moveLayer('threebox_layer', pandLayerId);

                this.mapSourceIds.push(pandLayerId);
                this.mapLayerIds.push(this.bag3dLayer.id);
                this.mapLayerIds.push(pandLayerId);

                this.bag3dLayer.toggleOn();
                this.removeMapPadding();
            }

            this.bag3dLayer.setOpacity(opacity);
            this.bag3dLayer.setBuildingColorMode(mode);
            this.updateLayerOpacity(pandLayerId, opacity);

            switch (mode) {
                case BuildingColorMode.NONE:
                    this.updateLayerFillExtrusionColor(pandLayerId, pandLayerExtrusionColor);
                    break;
                case BuildingColorMode.ENERGYLABEL:
                    this.updateLayerFillExtrusionColor(pandLayerId, pandLayerEnergyClassExtrusionColor);
                    break;
                case BuildingColorMode.BOUWJAAR:
                    this.updateLayerFillExtrusionColor(pandLayerId, pandLayerBouwjaarExtrusionColor);
                    break;
            }
        } else {
            if (hasLayers) {
                this.bag3dLayer.toggleOff();
                this.map.removeLayer(pandLayerId);
                this.map.removeLayer(bag3dLayerId);
                this.map.removeSource(pandLayerId);
                this.mapLayerIds = this.mapLayerIds.filter(it => it !== pandLayerId && it !== bag3dLayerId);
                this.mapSourceIds = this.mapSourceIds.filter(it => it !== pandLayerId);
            }

            this.map.moveLayer('threebox_layer', this.getLabelLayer().id);
        }
    }

    private updateVwprojectsLayer({active}: MapLayerConfig) {
        const hasLayer = this.mapLayerIds.indexOf(this.massaModelLayer.id) !== -1;

        if (active) {
            if (!hasLayer) {
                this.map.addLayer(this.massaModelLayer, this.getLayerIdToInsertBefore(this.getLabelLayer().id));
                this.mapLayerIds.push(this.massaModelLayer.id);
                this.massaModelLayer.toggleOn();
                this.removeMapPadding();
            }
        } else {
            if (hasLayer) {
                this.massaModelLayer.toggleOff();
                this.map.removeLayer(this.massaModelLayer.id);
                this.mapLayerIds = this.mapLayerIds.filter(it => it !== this.massaModelLayer.id);
            }
        }
    }

    private updateLotLayer({active, opacity}: MapLayerConfig) {
        const featureService = this.mapFeatureServices.find(it => it.sourceId === lotSourceKey);

        if (active) {
            if (!featureService) {
                this.mapFeatureServices.push(initLotFeatureService(this.map));

                this.addLayersOnMap(lotLayers, this.getLabelLayer().id);
            }

            this.updateLayerOpacity('lot-border', opacity);
            this.updateLayerOpacity('lot-label', opacity);
        } else {
            if (featureService) {
                this.removeLayersFromMap(lotLayers);
                featureService.destroySource();
                this.mapFeatureServices = this.mapFeatureServices.filter(it => it.sourceId !== lotSourceKey);
            }
        }
    }

    private updateBestemmingsplanLayer({active, opacity}: MapLayerConfig) {
        const enkelbestemmingFeatureService = this.mapFeatureServices.find(it => it.sourceId === enkelbestemmingSourceKey);
        const dubbelbestemmingFeatureService = this.mapFeatureServices.find(it => it.sourceId === dubbelbestemmingSourceKey);
        const bouwvlakFeatureService = this.mapFeatureServices.find(it => it.sourceId === bouwvlakSourceKey);

        const insertBeforeLayerId = 'threebox_layer';

        if (active) {
            if (!enkelbestemmingFeatureService) {
                this.mapFeatureServices.push(initEnkelbestemmingSource(this.map));
                this.addLayersOnMap(enkelbestemmingLayers, insertBeforeLayerId);
            }

            if (!dubbelbestemmingFeatureService) {
                this.mapFeatureServices.push(initDubbelbestemmingSource(this.map));
                this.addLayersOnMap(dubbelbestemmingLayers, insertBeforeLayerId);
            }

            if (!bouwvlakFeatureService) {
                this.mapFeatureServices.push(initBouwvlakSource(this.map));
                this.addLayersOnMap(bouwvlakLayers, insertBeforeLayerId);
            }

            for (const layer of enkelbestemmingLayers) {
                this.updateLayerOpacity(layer.id, opacity);
            }

            for (const layer of dubbelbestemmingLayers) {
                this.updateLayerOpacity(layer.id, opacity);
            }

            for (const layer of bouwvlakLayers) {
                this.updateLayerOpacity(layer.id, opacity);
            }
        } else {
            if (enkelbestemmingFeatureService) {
                this.removeLayersFromMap(enkelbestemmingLayers);
                enkelbestemmingFeatureService.destroySource();
                this.mapFeatureServices = this.mapFeatureServices.filter(it => it.sourceId !== enkelbestemmingSourceKey);
            }

            if (dubbelbestemmingFeatureService) {
                this.removeLayersFromMap(dubbelbestemmingLayers);
                dubbelbestemmingFeatureService.destroySource();
                this.mapFeatureServices = this.mapFeatureServices.filter(it => it.sourceId !== dubbelbestemmingSourceKey);
            }

            if (bouwvlakFeatureService) {
                this.removeLayersFromMap(bouwvlakLayers);
                bouwvlakFeatureService.destroySource();
                this.mapFeatureServices = this.mapFeatureServices.filter(it => it.sourceId !== bouwvlakSourceKey);
            }
        }
    }

    private updateNatura2000Layer({active, opacity}: MapLayerConfig) {
        const featureService = this.mapFeatureServices.find(it => it.sourceId === natura2000SourceKey);

        if (active) {
            if (!featureService) {
                this.mapFeatureServices.push(initNatura2000FeatureService(this.map));

                this.addLayersOnMap(natura2000Layers, 'threebox_layer');
            }

            for (const layer of natura2000Layers) {
                this.updateLayerOpacity(layer.id, opacity);
            }
        } else {
            if (featureService) {
                this.removeLayersFromMap(natura2000Layers);
                featureService.destroySource();
                this.mapFeatureServices = this.mapFeatureServices.filter(it => it.sourceId !== natura2000SourceKey);
            }
        }
    }

    private updateMaatvoeringLayer(
        {active, opacity, mode}: MapMaatvoeringLayerConfig,
        currentConfig: MapMaatvoeringLayerConfig | undefined
    ) {
        const sourceId = 'maatvoering';
        const hasSource = this.mapSourceIds.indexOf(sourceId) !== -1;
        const layers = mode === MaatvoeringLayerMode.NONE ? maatvoeringLayers : maatvoeringLayersExtrusion;
        const insertBeforeLayerId = this.getLayerIdToInsertBefore('threebox_layer');

        if (active) {
            if (!hasSource) {
                this.map.addSource(sourceId, maatvoeringSource);
                this.mapSourceIds.push(sourceId);
            }

            if (currentConfig && mode !== currentConfig.mode) {
                if (currentConfig.mode === MaatvoeringLayerMode.NONE) {
                    for (const layer of maatvoeringLayers) {
                        this.map.removeLayer(layer.id);
                        this.mapLayerIds = this.mapLayerIds.filter(it => it !== layer.id);
                    }
                }

                if (currentConfig.mode === MaatvoeringLayerMode.EXTRUSION) {
                    for (const layer of maatvoeringLayersExtrusion) {
                        this.map.removeLayer(layer.id);
                        this.mapLayerIds = this.mapLayerIds.filter(it => it !== layer.id);
                    }
                }
            }

            for (const layer of layers) {
                if (!this.map.getLayer(layer.id)) {
                    this.map.addLayer(layer, insertBeforeLayerId);
                    this.mapLayerIds.push(layer.id);
                }

                this.updateLayerOpacity(layer.id, opacity);
            }
        } else {
            if (hasSource) {
                for (const layer of layers) {
                    this.map.removeLayer(layer.id);
                    this.mapLayerIds = this.mapLayerIds.filter(it => it !== layer.id);
                }

                this.map.removeSource(sourceId);
                this.mapSourceIds = this.mapSourceIds.filter(it => it !== sourceId);
            }
        }
    }

    private updateLayerOpacity(layerId: string, opacity: number) {
        const layer = this.map.getLayer(layerId);
        if (layer) {
            if (layer.type === 'symbol') {
                this.map.setPaintProperty(layerId, 'icon-opacity', opacity);
                this.map.setPaintProperty(layerId, 'text-opacity', opacity);
            } else if ([
                'background',
                'fill',
                'line',
                'raster',
                'circle',
                'fill-extrusion',
                'heatmap'
            ].includes(layer.type)) {
                this.map.setPaintProperty(layerId, `${layer.type}-opacity`, ['*', opacity, opacity]);
            }
        }
    }

    private updateLayerFillExtrusionColor(layerId: string, fillExtrusionColor: any[]) {
        const layer = this.map.getLayer(layerId);

        if (layer) {
            this.map.setPaintProperty(layerId, 'fill-extrusion-color', fillExtrusionColor);
        }
    }

    private removeExtraSourcesAndLayers() {
        for (const layerId of this.mapLayerIds) {
            this.map.removeLayer(layerId);
        }

        for (const featureService of this.mapFeatureServices) {
            featureService.destroySource();
        }

        for (const sourceId of this.mapSourceIds) {
            this.map.removeSource(sourceId);
        }

        this.mapLayerIds = [];
        this.mapFeatureServices = [];
        this.mapSourceIds = [];
    }

    private is3D() {
        return Math.round(this.map.getPitch()) !== 0;
    }

    private updateRouteQueryParams(params: Params) {
        return this.ngZone.run(() => {
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: { ...this.activatedRoute.snapshot.queryParams, ...params },
                replaceUrl: true
            });
        });
    }

    private updateCameraPositionToQueryParameters() {
        const { location, zoom, pitch, bearing } = this.activatedRoute.snapshot.queryParams;
        if (location && zoom && pitch && bearing) {
            this.map.setZoom(zoom);
            this.map.setCenter(lngLatFromString(location));
            this.map.setPitch(pitch);
            this.map.setBearing(bearing);

            this.searchStateService.mode$.pipe(take(1)).subscribe(initialMode => {
                const paddingOptions = searchModePaddingOptions(initialMode, this.panelWidth);
                if (this.threeboxLayersEnabled()) {
                    paddingOptions.left = 0;
                }
                this.map.setPadding(paddingOptions);
            });
        }
    }

    private addSprites() {
        const imageOptions = { pixelRatio: 2 };
        const addImageFromUrl = (name: string, url: string) => {
            return this.map.loadImage(url, (err: any, image: any) => this.map.addImage(name, image, imageOptions));
        };

        addImageFromUrl('active-cluster', '/assets/sprites/marker-active-cluster.png');
        addImageFromUrl('active-company', '/assets/sprites/marker-active-company.png');
        addImageFromUrl('active-project', '/assets/sprites/marker-active-project.png');
        addImageFromUrl('slinger', '/assets/sprites/slinger.png');
        addImageFromUrl('blokjes', '/assets/sprites/blokjes.png');
        addImageFromUrl('cross', '/assets/sprites/cross.png');
        addImageFromUrl('projects', '/assets/sprites/marker-projects.png');
        addImageFromUrl('companies', '/assets/sprites/marker-companies.png');
        addImageFromUrl('cluster', '/assets/sprites/marker-cluster.png');
        addImageFromUrl('mixed-cluster', '/assets/sprites/marker-mixed-cluster.png');
        addImageFromUrl('poi-charging-station', '/assets/sprites/marker-electric.png');
        addImageFromUrl('poi-fuel', '/assets/sprites/marker-fuel.png');
        addImageFromUrl('poi-hospital-JP', '/assets/sprites/marker-hospital.png');
        addImageFromUrl('poi-police', '/assets/sprites/marker-politiebureau.png');
        addImageFromUrl('poi-fire-station', '/assets/sprites/marker-brandweer.png');
        addImageFromUrl('poi-ranger-station', '/assets/sprites/marker-basisschool.png');
        addImageFromUrl('poi-college', '/assets/sprites/marker-middelbareschool.png');
        addImageFromUrl('poi-college-JP', '/assets/sprites/marker-speciaal-onderwijs.png');
        addImageFromUrl('poi-rail', '/assets/sprites/marker-treinstation.png');
        addImageFromUrl('poi-bus', '/assets/sprites/marker-bus.png');
        addImageFromUrl('poi-rail-light', '/assets/sprites/marker-tram.png');
        addImageFromUrl('poi-airport', '/assets/sprites/marker-vliegveld.png');
    }

    private addDrawSprites() {
        const addImageFromUrl = (name: string, url: string) => {
            return this.map.loadImage(url, (err: any, image: any) => this.map.addImage(name, image));
        };

        // draw scale sprites
        addImageFromUrl('rotate', '/assets/sprites/rotate.png');
        addImageFromUrl('scale', '/assets/sprites/scale.png');
    }

    private createPopUp(latLng: [number, number]): mapboxgl.Popup {
        return new mapboxgl.Popup({ closeOnClick: true, closeButton: false })
            .setLngLat(latLng)
            .addTo(this.map);
    }
}
